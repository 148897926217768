<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">实训分类</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addTrainClassifies()">添加分类</el-button>
            </div>
        </div>

        <el-table
                :data="trainClassList"
                style="width: 100%; margin-top: 20px; flex: 1;"
                height="1%"
                size="medium"
                row-key="id"
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="class_name" label="名称"></el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <el-button size="small" type="text" v-if="scope.row.parent_id === 0" @click="addTrainSubClass(scope.row)">添加子分类</el-button>
                    <el-button size="small" type="text" @click="editTrainClass(scope.row)" v-if="scope.row.parent_id === 0">编辑</el-button>
                    <el-button size="small" type="text" @click="editTrainSubClass(scope.row)" v-if="scope.row.parent_id > 0">编辑</el-button>
                    <el-button size="small" type="text" @click="deleteTrainClass(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                v-if="trainClassList.length>0"
                style="margin-bottom: 10px"
                class="pages-center"
                :current-page="trainClassPages.currentPageNum"
                :page-size="trainClassPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="trainClassPages.total"
                @current-change="trainClassCurrentChange"
        ></el-pagination>
        <!--添加子分类弹窗-->
        <el-dialog :title="addTrainClassTitle" :visible.sync="dialogAddTrainClass" center width="500px" @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addTrainClassForm" ref="addTrainClassForm" :rules="trainClassRules" :label-position="labelPosition">
                <el-form-item label="分类名称" :label-width="formLabelWidth" prop="class_name">
                    <el-input v-model="addTrainClassForm.class_name" ref="nameInput" autocomplete="off" placeholder="1-20字符" style="width: 80%;" ></el-input>
                </el-form-item>
                <el-form-item label="上传封面" :label-width="formLabelWidth" prop="cover_img">
                    <el-button type="primary" v-model="addTrainClassForm.cover_img" class="upload-btn" size="medium">
                        <span>{{addTrainClassForm.cover_img ? '' : '上传封面'}}</span>
                        <input type="file" accept="image/jpg, image/jpeg, image/png" title="" @change="changeClassCoverFile($event)">
                    </el-button>
                    <div class="up-img-show" v-if="addTrainClassForm.cover_img">
                        <img :src="addTrainClassForm.cover_img" alt />
                        <div class="cover-bg">
                            <span class="re-upload">重新上传</span>
                            <input type="file" accept="image/jpg, image/jpeg, image/png" title="" @change="changeClassCoverFile($event)">
                        </div>
                    </div>
                    <span class="cover-text">尺寸：258x190px</span>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddTrainClass = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveTrainSubClassForm('addTrainClassForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "TrainClassManage",
        data(){
            return {
                //实训分类列表
                trainClassList:[],
                //分页
                trainClassPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //添加分类弹窗标题
                classTitleType: '添加分类',
                //是否显示弹窗
                dialogAddTrainClass: false,
                //弹窗标题
                addTrainClassTitle: '',
                distinguishBtn: '',
                //添加子分类表单
                addTrainClassForm: {
                    id: '',
                    class_name: '',
                    parent_id: '',
                    cover_img: '',
                    imageFile: '',
                    imageFilePath: ''
                },
                trainClassRules: {
                    class_name: [
                        { required: true, message: '请输入子分类名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    cover_img: [
                        { required: true, message: '请上传封面', trigger: 'change' }
                    ],
                },
                formLabelWidth: '80px',
                labelPosition: 'left',
            }
        },
        created(){
            this.getTrainClassList();
        },
        methods:{
            //获取分类
            getTrainClassList(){
                let params = {
                    type: 'page',
                    page: this.trainClassPages.currentPageNum,
                    pageSize:this.trainClassPages.eachPageNum
                };
                this.$http.axiosGetBy(this.$api.trainClass_list, params ,(res)=>{
                    if(res.code === 200){
                        this.trainClassList = res.data.data;
                        this.trainClassPages.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //添加分类
            addTrainClassifies(){
                let that = this;
                that.$prompt('分类名称','添加分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value })=>{
                    let formData = new FormData();
                    formData.append('class_name',value);
                    that.$http.axiosPost(that.$api.trainClass_save,formData,(res)=>{
                        if (res.code === 200) {
                            that.getTrainClassList();
                        } else {
                            that.$message.error(res.message);
                        }
                    });
                    that.$message({
                        type: 'success',
                        message:'添加'+ value + '成功'
                    })
                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消添加'
                    })
                })
            },
            //编辑分类
            editTrainClass(row){
                let that = this;
                const name = row.class_name;
                const id = row.id;
                that.$prompt('分类名称','编辑分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder: name
                }).then(({value})=>{
                    if (value === null || value === '') {
                        this.$message.error('修改失败')
                    } else {
                        let formData = new FormData();
                        formData.append('id',id);
                        formData.append('class_name',value);
                        that.$http.axiosPost(that.$api.trainClass_save,formData,(res)=>{
                            if(res.code === 200){
                                this.$message.success(res.message);
                                that.getTrainClassList();
                            }else{
                                this.$message.warning(res.message)
                            }
                        },(err)=>{
                            console.log(err)
                        });
                    }
                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消编辑'
                    })
                })
            },
            //删除分类
            deleteTrainClass(row){
                let that = this;
                const id = row.id;
                that.$confirm('删除分类后不能恢复，确定要删除吗？','删除分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    let param = {
                        id: Number(row.id)
                    }
                    that.$http.axiosGetBy(that.$api.trainClass_del, param ,(res)=>{
                        if(res.code === 200){
                            that.$message({
                                type: 'success',
                                message:'删除成功'
                            })
                            that.getTrainClassList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })

                }).catch(()=>{
                    that.$message({
                        type: 'info',
                        message:'已取消删除'
                    })
                })
            },
            //添加子分类弹窗
            addTrainSubClass(row){
                this.dialogAddTrainClass = true;
                this.distinguishBtn = 'add';
                this.addTrainClassTitle = '添加子分类';
                this.addTrainClassForm.parent_id = row.parent_id;
                this.addTrainClassForm.id = row.id;
            },
            //上传封面
            changeClassCoverFile(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                let maxSize = 1024 * 1024 * 2;
                this.addTrainClassForm.imageFile = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    // url = this.result.substring(this.result.indexOf(',') + 1);
                    // that.addClassForm.image = "data:image/png;base64," + url;

                    let img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        if (img.width === 258 && img.height === 190 && fileList.size <= maxSize) {
                            let formData = new FormData();
                            formData.append('file', that.addTrainClassForm.imageFile);
                            that.$http.axiosPost(that.$api.trainClass_uploadCoverImg, formData, (res) => {
                                if (res.code === 200) {
                                    that.addTrainClassForm.imageFilePath = res.data.url;
                                    that.addTrainClassForm.cover_img = res.data.src;
                                    that.$message.success(res.message);
                                } else {
                                    that.$message.error(res.message);
                                }
                            }, (err) => {
                                console.log(err);
                            })
                        } else {
                            that.$message({
                                type: "error",
                                message: "图片尺寸必须为 258*190，大小不能超过2M",
                            });
                        }
                    }
                }
                event.target.value = '';
            },
            //编辑子分类弹窗
            editTrainSubClass(row) {
                this.dialogAddTrainClass = true;
                this.distinguishBtn = 'edit';
                this.addTrainClassTitle = '编辑子分类';
                this.addTrainClassForm.class_name = row.class_name;
                this.addTrainClassForm.parent_id = row.parent_id;
                this.addTrainClassForm.id = row.id;
                let param = {
                    id: Number(row.id)
                }
                this.$http.axiosGetBy(this.$api.trainClass_get, param, (res) => {
                    if (res.code === 200) {
                        this.addTrainClassForm.cover_img = res.data.cover_img.src;
                        this.addTrainClassForm.imageFilePath = res.data.cover_img.url;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //保存子分类
            saveTrainSubClassForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('class_name',this.addTrainClassForm.class_name);
                            formData.append('parent_id',this.addTrainClassForm.id);
                            formData.append('cover_img',this.addTrainClassForm.imageFilePath);
                            this.$http.axiosPost(this.$api.trainClass_save,formData,(res)=>{
                                if (res.code === 200) {
                                    this.dialogAddTrainClass = false;
                                    this.getTrainClassList();
                                    this.$message.success(res.message);
                                } else {
                                    this.$message.error(res.message);
                                }
                            }, (err) => {
                                console.log(err);
                            });
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('id',this.addTrainClassForm.id);
                            formData.append('class_name',this.addTrainClassForm.class_name);
                            formData.append('parent_id',this.addTrainClassForm.parent_id);
                            formData.append('cover_img',this.addTrainClassForm.imageFilePath);
                            this.$http.axiosPost(this.$api.trainClass_save,formData,(res)=>{
                                if (res.code === 200) {
                                    this.dialogAddTrainClass = false;
                                    this.getTrainClassList();
                                    this.$message.success(res.message);
                                } else {
                                    this.$message.error(res.message);
                                }
                            }, (err) => {
                                console.log(err);
                            });
                        }

                    } else {
                        return false;
                    }
                });
            },
            //重置
            resetForm() {
                this.addTrainClassForm = {
                    id:'',
                    class_name: '',
                    parent_id: '',
                    cover_img: '',
                }
            },
            //分页
            trainClassCurrentChange(val) {
                this.trainClassPages.currentPageNum = val;
                this.getTrainClassList()
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 12px 9px;
    }

    .upload-btn {
        position: relative;
        width: 122px;
        height: 90px;
        background: #409EFF;
        border-color: transparent;
        span {
            display: block;
            width: 100%;
            height: 100%;
            line-height: 70px;
            position: relative;
        }
        input {
            width: 122px;
            height: 90px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
    .up-img-show {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 122px;
        height: 90px;
        background-color: #fff;
        border: 1px solid #bfbfbf6b;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .cover-bg {
            position: absolute;
            bottom: 0;
            width: 122px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: #fff;
            background: #000000;
            opacity: 0.5;
            input {
                width: 122px;
                height: 30px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .cover-text {
        position: absolute;
        bottom: -48px;
        left: 0;
        font-size: 12px;
        color: #999;
    }
</style>